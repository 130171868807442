import {VOUCHER_TYPES} from "@/constants";
import {PAYMENT_TYPES} from "@/constants/payment";
import {checkoutMutation, payVoucherMutation} from "@/plugins/API/voucher";

export const useVoucherStore = defineStore("voucher", () => {

    const selectedPayment = ref(null)
    const cardDetails = ref("")

    const giftCredit = ref(true)
    const firstTime = ref(true)
    const desktopActiveTab = ref(VOUCHER_TYPES.GIFT_VOUCHER)
    const mobileActiveTab = ref(VOUCHER_TYPES.SEND_GIFTS)
    const selectedBalance = ref(0)
    const selectedColor = ref('')
    const selectedCard = ref('')
    const balance = ref('')
    const email = ref('')
    const from_name = ref('')
    const to_name = ref('')
    const to_phone = ref('')
    const message = ref('')
    const suggested_message = ref('')
    const video = ref(null)
    const image = ref(null)
    const audio = ref(null)
    const link = ref(null)
    const cvv = ref('')
    const voucherColorId = ref(1)
    const voucherCardId = ref(1)
    const voucherMediaType = ref(null)

    const checkoutData = ref()

    const {isLoading: checkoutLoading, mutateAsync} = checkoutMutation()

    const fetchCheckout = () => {
        mutateAsync({payment_method: selectedPayment.value, cardDetails: cardDetails.value})
            .then(({data}) => {
                checkoutData.value = data
                firstTime.value = false
            })
    }


    const {isLoading: payLoading, mutateAsync: payMutation} = payVoucherMutation()
    const payCheckout = () => {
        payMutation(({...cardDetails.value, cvv: cvv.value})).then(({data}) => {
            if (data?.payment_response?._links.redirect.href) {
                window.location =
                    data.payment_response._links.redirect.href
            }
        })
    }

    const loading = computed(() => checkoutLoading.value || payLoading.value || false)
    const $reset = () => {
        selectedBalance.value = 0
        selectedColor.value = ''
        selectedCard.value = ''
        balance.value = ''
        email.value = ''
        from_name.value = ''
        to_name.value = ''
        to_phone.value = ''
        message.value = ''
        cardDetails.value = ''
        checkoutData.value = ''
        selectedPayment.value = null
        desktopActiveTab.value = VOUCHER_TYPES.GIFT_VOUCHER
        mobileActiveTab.value = VOUCHER_TYPES.SEND_GIFTS
        cvv.value = ''
        firstTime.value = true
        voucherColorId.value = 1
        voucherCardId.value = 1,
            suggested_message.value = null
        voucherMediaType.value = false
        video.value = null
        audio.value = null
        link.value = null
        image.value = null
    }

    return {
        desktopActiveTab,
        mobileActiveTab,
        selectedPayment,
        cardDetails,
        selectedBalance,
        selectedCard,
        selectedColor,
        balance,
        email,
        from_name,
        to_name,
        to_phone,
        message,
        loading,
        cvv,
        firstTime,
        giftCredit,
        voucherColorId,
        voucherCardId,
        data: checkoutData,
        fetchCheckout,
        payCheckout,
        $reset,
        voucherMediaType,
        suggested_message,
        video,
        audio,
        link,
        image
    };
});