<template>
  <van-popup
      :show="isModelShown"
      :style="{ maxHeight: '75%', width: $device.isDesktop ? '450px' : '85%' }"
      class="!overflow-y-hidden"
      position="center"
      @close="onClose"
  >
    <div class="h-full w-full">
      <niceone-image :src="popupItem?.image" class="w-full h-full object-cover"/>
      <div
          class="popup-timer absolute ms-3 top-3 inline-flex items-center bg-[#00000060] text-white rounded-full h-[30px] px-3 cursor-pointer"
          @click="onClose">
        <div class="me-1 text-xs">{{ $t('common.skip') }}</div>
        <van-count-down :time="timer + 1000" @finish="onClose">
          <template #default="timeData">
            <div class="text-white text-xs">{{ timeData.seconds }}</div>
          </template>
        </van-count-down>
      </div>
    </div>

  </van-popup>
</template>

<script setup>
import {useContentStore} from "@/store/content";
import {isValidTimestamp} from "@/utilities";

const contentStore = useContentStore()
const {content, isPopupLoaded, isSmartBannerShown, isCookieBannerShown} = storeToRefs(contentStore)

const popup = computed(() => content.value?.popup || null)
const popupItem = computed(() => popup.value?.length ? popup.value[0] : null)

// Use timer from API if available; otherwise, fallback to 10 seconds
const timer = ref((popupItem.value?.timer ?? 10) * 1000);

const isModelShown = computed(() => {
  if (!isValidTimestamp(popupItem.value?.timestamp, false)) return

  const isPopup = popup.value?.length && !isPopupLoaded.value && !isCookieBannerShown.value
  return useNuxtApp().$device.isDesktop ? isPopup : isPopup && !isSmartBannerShown.value
})

const onClose = () => {
  isPopupLoaded.value = true
}
</script>

<style scoped>
</style>