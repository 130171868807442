<template>
  <nuxt-link v-for="(brand, brandIndex) in brands" :key="brandIndex"
             :to="formattedSeoUrl(brand)"
             class="relative flex-center rounded-md bg-white border h-20 p-2" @click.prevent="toAuthentication(brand)">
    <niceone-image :src="brand.image" class="h-16"/>
  </nuxt-link>
</template>

<script setup>
import {useAuth} from '@/composables/useAuth'
import {BANNER_TYPE_FOR_AUTH} from '@/constants'
import {formattedSeoUrl} from "@/utilities";

defineProps({
  brands: {
    type: Array,
    default: () => []
  }
})

const {useRedirection} = useAuth()
const toAuthentication = (item) => {
  const type = parseInt(item?.type)
  if (type === BANNER_TYPE_FOR_AUTH) {
    useRedirection(item)
  }
}
</script>

<style scoped>
</style>