<template>
  <div class="col-span-12 md:col-span-5 grid grid-cols-1 gap-3 p-3">
    <div class="grid grid-cols-3 gap-3 auto-rows-min">
      <nuxt-link v-for="(banner ,index) in data.banners" :key="index"
                 :to="formattedSeoUrl(banner)"
                 class="row-span-3"
                 @click.prevent="toAuthentication(banner)">
        <niceone-image :src="$publicPath(banner.image)" class="rounded-md w-full"/>
      </nuxt-link>
      <BrandsGrid :brands="data?.brands"/>
    </div>
  </div>
</template>

<script setup>
import {useAuth} from '@/composables/useAuth'
import {BANNER_TYPE_FOR_AUTH} from '@/constants'
import BrandsGrid from "@/components/common/dynamic-menu-components/BrandsGrid";
import {formattedSeoUrl} from "@/utilities";

const props = defineProps({
  data: {
    type: Object,
    default: () => {
    }
  },
})

const {useRedirection} = useAuth()
const toAuthentication = (item) => {
  const type = parseInt(item?.type)
  if (type === BANNER_TYPE_FOR_AUTH) {
    useRedirection(item)
  }
}
</script>