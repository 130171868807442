<template>
  <div
      class="bg-white ltr:left-0 rtl:right-0 custom-container top-14 shadow-cus absolute z-10 menu-niceone group-hover">
    <div class="grid grid-cols-12 min-h-[450px] max-h-auto w-full">
      <div class="col-span-12 py-3 md:col-span-7">
        <empty-items :emptySubCategories="emptySubCategories"/>
        <sub-items :subCategories="subCategories"/>
      </div>
      <menu-types :selected-slug="slug"/>
    </div>
  </div>
</template>

<script setup>
import {useCategoryStore} from "@/store/category";
import SubItems from "@/components/common/menuitems/SubItems";
import EmptyItems from "@/components/common/menuitems/EmptyItems";
import MenuTypes from "@/components/common/dynamic-menu-components/index";

const props = defineProps({
  slug: {
    Type: String,
    default: ""
  }
});

const categoryStore = useCategoryStore();
const {getCategories} = storeToRefs(categoryStore)

const selectedCategory = computed(() => getCategories?.value.find(item => item?.seo_name === props.slug) || [])
const subCategories = computed(() => selectedCategory?.value?.categories.filter(item => item.categories.length > 0))
const emptySubCategories = computed(() => selectedCategory?.value?.categories.filter(item => item.categories.length === 0))
</script>